import React, { Component } from "react";
import { Row, Col, Button, Label, FormGroup, Input } from "reactstrap";
import { Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import moment from "moment";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

// import Swiper core and required modules
import SwiperCore, {
  EffectCoverflow,
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper";
import { Swiper, SwiperSlide, } from "swiper/react";

import Http from "../../helpers/Http";
import ApiRoutes from "../../helpers/ApiRoutes";
import MainContext from "../../helpers/MainContext";

// install Swiper modules
SwiperCore.use([EffectCoverflow, Navigation, Pagination, Scrollbar, A11y, Autoplay]);

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const passwordRegExp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;

const FormSchema = Yup.object().shape({
  name: Yup.string()
    .required("Please enter a name")
    .min(2, "Name must contain atleast 2 letters.")
    .max(50, "Name must contain atmost 50 letters."),
  email: Yup.string()
    .required("Please enter email address")
    .email("Please enter valid email id")
    .max(50, "Please enter email id with atmost 50 letters"),
  phone: Yup.string()
    .required("Please enter phone number")
    .matches(phoneRegExp, "Please enter valid phone number")
    .min(7, "Phone number must contain atleast 7 letters.")
    .max(15, "Phone number must contain atmost 15 letters."),
  message: Yup.string().required("Please enter a message")
    .max(500, "Message must contain atmost 500 letters."),
});

export default class Home extends Component {
  static contextType = MainContext;
  constructor(props) {
    super(props);

    this.state = {

      bannersList: [],
      screensList: [],

      name: "",
      email: "",
      phone: "",
      message: "",
    };
  }

  componentDidMount() {
    this.getBanners();
    this.getScreens();
  }

  getBanners() {
    var bannersList = [
      "./assets/banner/banner1.png",
      "./assets/banner/banner2.png",
      "./assets/banner/banner3.png",
    ];

    this.setState({
      bannersList: bannersList,
    });
  }

  getScreens() {
    var screensList = [
      "./assets/overview/18.jpg",
      "./assets/overview/17.jpg",
      "./assets/overview/16.jpg",
      "./assets/overview/1.jpg",
      "./assets/overview/3.jpg",
      "./assets/overview/5.jpg",
      "./assets/overview/7.jpg",
      "./assets/overview/8.jpg",
      "./assets/overview/9.jpg",
      "./assets/overview/11.jpg",
      "./assets/overview/13.jpg",
      "./assets/overview/14.jpg",
      "./assets/overview/15.jpg",
      "./assets/overview/16.jpg",
      "./assets/overview/17.jpg",
      "./assets/overview/18.jpg",
      "./assets/overview/22.jpg",
      "./assets/overview/1.jpg",
      "./assets/overview/3.jpg",
    ];

    this.setState({
      screensList: screensList,
    });
  }


  handleSubmit = async (inputValues, formOptions) => {
    let formData = new FormData();
    formData.append("username", inputValues.name);
    formData.append("email", inputValues.email);
    formData.append("phone", inputValues.phone);
    formData.append("message", inputValues.message);

    let path = ApiRoutes.SAVE_CONTACT_US_REQUEST;
    const res = await Http("POST", path, formData);

    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {

        formOptions.resetForm();
        NotificationManager.success(resJson.message, "Success!", 3000);

      } else {

        if (resJson.data.errors && resJson.data.errors.length > 0) {

          resJson.data.errors.forEach((error) => {
            formOptions.setFieldError(error.errField, error.errText);
          });

        } else {
          NotificationManager.error(resJson.message, "Error!", 3000);
        }
      }
    } else {
      NotificationManager.error("Internal Server Error", "Error!", 3000);
    }
  };

  render() {

    var contentsData = this.context.contentsData;

    return (
      <>
        {this.state.bannersList.length > 0 && (
          <section id="banner" className="banner">
            <Swiper
              spaceBetween={30}
              slidesPerView={1}
              pagination={{ clickable: true }}
              autoplay={{ delay: 3000 }}
            >
              {this.state.bannersList.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <img className="" src={item} />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </section>
        )}

        {contentsData && contentsData.contents && (
          <section id="about-us" className="about-us">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="web-heading text-center">
                    <h2 className="">About Us</h2>
                  </div>

                  <div className="about-us-content" dangerouslySetInnerHTML={{
                    __html: contentsData && contentsData.contents ? contentsData.contents.about_us : "",
                  }}></div>
                </div>
              </div>
            </div>
          </section>
        )}

        <section id="downloads" className="downloads">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-7">
                <div className="navodaya-app-text">
                  <div className="web-heading">
                    <h2>Download Navodaya App</h2>
                  </div>
                  <h3>Become a part of Navodaya App and extends your helping hands </h3>
                  <ul>
                    <li>
                      <a href={contentsData && contentsData.settings ? contentsData.settings.live_app_links.android : ""} target="_blank">
                        <img src="./assets/img/mobile-android.png" alt="item" className="img-fluid" />
                      </a>
                    </li>
                    <li>
                      <a href={contentsData && contentsData.settings ? contentsData.settings.live_app_links.ios : ""} target="_blank">
                        <img src="./assets/img/mobile-ios.png" alt="item" className="img-fluid" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-5 d-flex align-items-end">
                <div className="mobile-img">
                  <img src="./assets/img/phone1.png" alt="mobile" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="overview" className="overview">

          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="web-heading text-center">
                  <h2 className="text-white">Overview</h2>
                </div>
              </div>
            </div>
          </div>

          <Swiper
            className="overview-slider"
            spaceBetween={20}
            slidesPerView={5}
            effect="coverflow"
            // centeredSlides={true}
            autoplay={true}
            breakpoints={{
              320: {
                slidesPerView: 3,
                spaceBetween: 20
              },
              480: {
                slidesPerView: 3,
                spaceBetween: 30
              },
              1000: {
                slidesPerView: 6,
                spaceBetween: 40
              },
            }}
          >
            {this.state.screensList.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <img
                    className=""
                    src={item}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </section>

        <section id="contact-us" className="contact-us">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-3 col-md-3 contact-left align-items-center">
                <h2>NAVOLAYA</h2>
                <h3>Contact Us</h3>
              </div>
              <div className="col-lg-9 col-md-9 contact-right">
                <h4>HAVE ANY QUESTIONS</h4>
                <Formik
                  initialValues={{
                    name: this.state.name,
                    email: this.state.email,
                    phone: this.state.phone,
                    message: this.state.message,
                  }}
                  validationSchema={FormSchema}
                  onSubmit={this.handleSubmit}
                >
                  {({
                    handleSubmit,
                    setFieldValue,
                    setFieldTouched,
                    handleChange,
                    values,
                    errors,
                    touched,
                    isSubmitting,
                  }) => (
                    <Form className="login_signup_form">
                      <Row>
                        <Col xs={12} md={6}>
                          <FormGroup>
                            <Field
                              className="form-control"
                              name="name"
                              type="text"
                              placeholder="Name"
                              maxlength={50}
                            />
                            {errors.name && touched.name ? (
                              <div className="invalid-feedback d-block">
                                {errors.name}
                              </div>
                            ) : null}
                          </FormGroup>
                          <FormGroup>
                            <Field
                              className="form-control"
                              name="email"
                              type="email"
                              placeholder="Email Address"
                              maxlength={50}
                            />
                            {errors.email && touched.email ? (
                              <div className="invalid-feedback d-block">
                                {errors.email}
                              </div>
                            ) : null}
                          </FormGroup>
                          <FormGroup>
                            <Field
                              className="form-control"
                              name="phone"
                              type="text"
                              placeholder="Phone Number"
                              maxlength={15}
                            />
                            {errors.phone && touched.phone ? (
                              <div className="invalid-feedback d-block">
                                {errors.phone}
                              </div>
                            ) : null}
                          </FormGroup>
                        </Col>

                        <Col xs={12} md={6}>
                          <FormGroup>
                            <Field
                              className="form-control"
                              name="message"
                              type="text"
                              component="textarea"
                              rows={6}
                              placeholder="Message"
                              maxlength={500}
                            />
                            {errors.message && touched.message ? (
                              <div className="invalid-feedback d-block">
                                {errors.message}
                              </div>
                            ) : null}
                          </FormGroup>
                        </Col>
                      </Row>
                      <div className="trems_use_text" color="muted">
                        <Button
                          className="w-30 btn navodaya-app-btn text-uppercase"
                          type="submit"
                        >
                          Submit
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
